import './RoleDetail.css';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DetailTopSection from '../../components/detail-top-section/DetailTopSection';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import RoleDTO from '../../dto/RoleDTO';
import RoleService from '../../services/role/RoleService';
import ScheduleDTO from '../../dto/ScheduleDTO';
import ScheduleService from '../../services/schedule/ScheduleService';
import TableRow from '../../components/tablerow/TableRow';

function RoleDetail() {
  const [role, setRole] = useState({} as RoleDTO);
  const [schedules, setSchedules] = useState([] as ScheduleDTO[]);
  const scheduleService = new ScheduleService;

  useEffect(() => {
    new RoleService().loadOne(id)
    .then(val => {
      setRole(val);
    })
    scheduleService.getSchedule(id).then(response => {
      setSchedules(response.data);
    });
  }, [])

  const params = useParams();

  if(params.id === undefined) {
    return <div></div>
  }

  const id: number = Number.parseInt(params.id);

  if(!role || !role.allowedPermissions) {
    return <div></div>
  }

  return (
    <div>
      <Breadcrumb lastItem={role.name}/>
      <DetailTopSection pageTitle={role.name} buttonTitle={'Wijzigen'} navigationLink={'/rollen/wijzigen/' + role.id} subheading={'Rol'}/>
      <div className="permissions">
        <h4>Permissies</h4>
        {
        role.allowedPermissions.length !== 0  ? 
        role.allowedPermissions.map(permission => {
          return <p id={permission.key}>{permission.display}</p>
        }) : 
        <p>Er zijn nog geen gekoppelde permissies!</p>
        }
      </div>
      <h4 className="input-label">Standaard planning</h4>
      <div className='schedules'>
        {
          schedules.map(x => {
            return <div key={x.id}>
              <TableRow subtitle={scheduleService.getTimeSubString(x.from) + "-" + scheduleService.getTimeSubString(x.to)} title={scheduleService.getDateSubString(x.date)}></TableRow>
            </div>
          })
        }
      </div>
    </div>
  );
}

export default RoleDetail;
