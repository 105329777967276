import './CommandDetail.css';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DetailTopSection from '../../../components/detail-top-section/DetailTopSection';
import CommandDTO from '../../../dto/CommandDTO';
import CommandService from '../../../services/intervention/command/CommandService';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import GoalDTO from '../../../dto/GoalDTO';
import GoalService from '../../../services/goal/GoalService';
import TableRow from '../../../components/tablerow/TableRow';

function CommandDetail() {
  const [command, setCommand] = useState({} as CommandDTO);
  const [goal, setGoal] = useState({} as GoalDTO);

  const getCommand = async () => {
    const service = new CommandService()
    service.loadOne(id)
    .then(val => {
      setCommand(val);
    })
  };

  const getGoal = () => {
    const goalService = new GoalService();
    if(command.goal_id){
      goalService.loadOne(command.goal_id)
      .then(val => {
        setGoal(val);
      })
    }
  };

  const params = useParams();
  useEffect(() => {
    getCommand()
  }, [])

  useEffect(() => {
    getGoal()
  }, [command.goal_id])

  if(params.id === undefined) {
    return <div></div>
  }

  const id: number = Number.parseInt(params.id);

  return (
    <div>
      <Breadcrumb lastItem={command.name}/>
      <DetailTopSection pageTitle={command.name} buttonTitle={'Wijzigen'} navigationLink={'/interventies/commando/wijzigen/' + command.id} subheading={'Interventie'}/>
      <table className="question-detail-table">
        <tbody>
          <tr>
            <td className="table-min-width">Interventie</td>
            <td>Commando</td>
          </tr>
          <tr>
            <td className="table-min-width">Doelstelling</td>
            <td>{goal.name}</td>
          </tr>
          <tr>
            <td className="table-min-width">Commando</td>
            <td>{command.commandText}</td>
          </tr>
          <tr>
            <td className="table-min-width">Interventie zonder locatie</td>
            <td>{command.alwaysShow ? "Ja" : "Nee"}</td>
          </tr>
        </tbody>
      </table> 
      <div className="dateTimes">
            <h3>interventie tijden</h3>
            {command.dateTime &&
              command.dateTime.length > 0 ?
              command.dateTime.map(dateTime => {
                return <TableRow wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                  subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
              })
              : <h5>Geen specifieke tijden</h5>
            }
        </div>
    </div>
  );
}

export default CommandDetail; 
