import './QuestionnaireEdit.css';
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Input from '../../components/input/Input';
import SubmitButton from '../../components/submit-button/SubmitButton';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import QuestionnaireDTO from '../../dto/QuestionnaireDTO';
import QuestionnaireService from '../../services/intervention/questionnaire/QuestionnaireService';
import QuestionDTO from '../../dto/QuestionDTO';
import TrashIcon from '../../assets/icons/delete.svg';
import AnswerDTO from '../../dto/AnswerDTO';
import { CloseButton } from 'react-toastify/dist/components';
import CloseModalButton from '../../components/closebutton/CloseModalButton';
import Select from '../../components/select/Select';
import GoalDTO from '../../dto/GoalDTO';
import Option from '../../components/select/Option';
import GoalService from '../../services/goal/GoalService';
import TableRow from '../../components/tablerow/TableRow';
import DateTimeSelect from '../../components/dateTimeSelect/DateTimeSelect';
import DateTimeDTO from '../../dto/DateTimeDTO';
import RadioButton from '../../components/radioButton/RadioButton';
import QuestionTypes from '../../types/questionTypes'

const QuestionnaireEdit : React.FC = () => {
  const [questionnaire, setQuestionnaire] = useState({} as QuestionnaireDTO);
  const [service, setService] = useState({} as QuestionnaireService);
  const [errors, setErrors] = useState({} as any);
  const [allGoals, setAllGoals] = useState([] as GoalDTO[]);
  const [goal, setGoal] = useState(0 as number);
  const [currentGoal, setCurrentGoal] = useState({} as GoalDTO);
  const [startDate, setStartDate] = useState(null as unknown);
  const [time, setTime] = useState('' as string);
  const [day, setDay] = useState(String);
  const [dateTimes, setDateTimes] = useState([] as DateTimeDTO[]);
  const [timeTM, setTimeTM] = useState('' as string);
  const [dateTm, setDateTM] = useState(null as unknown);
  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if(!isEdit) {
      setQuestionnaire({...questionnaire, goal_id: Number(id)});
      questionnaire.questions.forEach(question => {
        if(question.questionType == "Open vraag"){
            question.answers = [];
        }
      });
      await service.create(questionnaire)
        .then(() => {
          toast.success("Vragenlijst aangemaakt!");
          navigate("/interventies");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    } else {
      await service.update(questionnaire)
        .then(response => {
          toast.success("Vragenlijst bijgewerkt!");
          navigate("/interventies");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    }
  }

  const getCurrentGoal = () => {
    if(questionnaire.goal_id){
      const goalService = new GoalService();
      goalService.loadOne(questionnaire.goal_id)
      .then(val => {
        setCurrentGoal(val);
      })
    }
  };

  useEffect(() => {
    const questionnaireService = new QuestionnaireService();
    const goalService = new GoalService();
    goalService.loadAll()
    .then(val => {
      setAllGoals(val);
    });
    setService(questionnaireService)
    if(!isEdit) {
      let questionnaireDTO: QuestionnaireDTO = new QuestionnaireDTO();
      setQuestionnaire(questionnaireDTO);
    } else {
      questionnaireService.loadOne(id)
      .then(val => {
        setQuestionnaire(val);
      })
    }
  }, [])

  useEffect(() => {
    getCurrentGoal()
  }, [questionnaire.goal_id])

  const deleteQuestion = (id : number) : any => {
    questionnaire.questions = questionnaire.questions.filter(x => x.id !== id);
    setQuestionnaire(Object.assign({}, questionnaire));
  }

  const addQuestion = () : any => {
    const newQuestion = new QuestionDTO();
    let id = 1;
    if(questionnaire.questions.length !== 0) {
      id = Math.max(...questionnaire.questions.map(x => x.id)) + 1;
    }
    newQuestion.id = id;
    questionnaire.questions.push(newQuestion);
    setQuestionnaire(Object.assign({}, questionnaire));
  }

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setQuestionnaire({...questionnaire, [e.target.id]: e.target.value})
  }

  const handleQuestionChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const questionId = e.target.id;
    const question = questionnaire.questions.find(x => x.id.toString() === questionId);
    if(question !== undefined) {
      question.name = e.target.value;
      question.question = e.target.value;
      setQuestionnaire(Object.assign({}, questionnaire));
    }
  }

  const deleteAnswer = (questionId : number, answerId : number) : any => {
    const question = questionnaire.questions.find(x => x.id === questionId)!;

    question.answers = question.answers.filter(x => x.id !== answerId);
    setQuestionnaire(Object.assign({}, questionnaire));
  }

  const addAnswer = (questionId: number) : any => {
    const question = questionnaire.questions.find(x => x.id === questionId)!;
    const newAnswer = new AnswerDTO();
    let id = 1;
    if(question.answers.length !== 0) {
      id = Math.max(...question.answers.map(x => x.id)) + 1;
    }
    newAnswer.id = id;
    question.answers.push(newAnswer);
    setQuestionnaire(Object.assign({}, questionnaire));
  }

  const handleAnswerChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const combinedId = e.target.id;
    const questionId = combinedId.split("-")[0];
    const question = questionnaire.questions.find(x => x.id.toString() === questionId)!;
    const answerId = combinedId.split("-")[1];
    const answer = question.answers.find(x => x.id.toString() === answerId);
    if(answer !== undefined) {
      answer.answerText = e.target.value;
      setQuestionnaire(Object.assign({}, questionnaire));
    }
  }

  const updateGoal = (id:number) => {
    setGoal(id);
    setQuestionnaire({...questionnaire, goal_id: Number(id)});
  }

  const addDateTime = (e: FormEvent) => {
    e.preventDefault();

    var dateArray : DateTimeDTO[] = [];
    if(questionnaire.dateTime){
      dateArray = questionnaire.dateTime;
    }

    const newDateTime : DateTimeDTO = new DateTimeDTO();
    if(day){newDateTime.day = day;}
    if(startDate && startDate instanceof Date){
      newDateTime.date = startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear();
    }
    if(time){newDateTime.time = time;}else{newDateTime.time = ""}
    if(dateTm && dateTm instanceof Date){newDateTime.dateTm = dateTm.getDate() + "-" + (dateTm.getMonth() + 1) + "-" + dateTm.getFullYear();}
    if(timeTM){newDateTime.timeTm = timeTM;}
    newDateTime.id = questionnaire.dateTime ? questionnaire.dateTime.length + 1 : 0;
    dateArray.push(newDateTime);

    const newQuestionnaire : QuestionnaireDTO = new QuestionnaireDTO();
    newQuestionnaire.goal_id = questionnaire.goal_id;
    newQuestionnaire.id = questionnaire.id;
    newQuestionnaire.name = questionnaire.name;
    newQuestionnaire.dateTime = dateArray;
    newQuestionnaire.questions = questionnaire.questions;
    newQuestionnaire.alwaysShow = questionnaire.alwaysShow;

    setQuestionnaire(newQuestionnaire);
  }

  const removeDateTime = (id:number) => {
    const newQue : QuestionnaireDTO = new QuestionnaireDTO(questionnaire);
    if(newQue.dateTime){
      newQue.dateTime = newQue.dateTime.filter(x => x.id !== id);
    }
    setQuestionnaire(newQue);
  }

  const handleTypeChoice = (type: string, id: number) => {
    const question = questionnaire.questions.find(x => x.id.toString() === id.toString());
    if(question !== undefined) {
      question.questionType = type;
      setQuestionnaire(Object.assign({}, questionnaire));
    }
  }

  
  const handleAlwaysShow = (option: string) => {
    questionnaire.alwaysShow = (option == "ja" ? true : false);
    setQuestionnaire({...questionnaire, alwaysShow: (option == "ja" ? true : false)});

  }
  
  const id: number = Number.parseInt(params.id === undefined ? "0" : params!.id);
  const isEdit: boolean = id !== 0;

  if(!questionnaire || !questionnaire.questions) {
    return <div></div>
  }
  
  return (
    <div className='edit-questionnaire-page'>

      <Breadcrumb lastItem={questionnaire.name} itemsToRemove={["vragenlijst"]}/>
      <h2>{isEdit ? questionnaire.name + " Wijzigen" : "Vragenlijst aanmaken"}</h2>

      <div className='flex-container'>

        <div className="column">
          <div className="form">
            <form onSubmit={onSubmit}>
              <Input placeholderText={'Naam'} inputName={'name'} inputType={'text'} inputLabel={'Naam'} onChange={handleChange} value={questionnaire.name} errors={errors.name}/>
              <br/>
                <Select dots={false} placeholderText={currentGoal.name} value={goal.toString()} selectName={'Goalid'} selectLabel={'Doelstelling'} onChange={updateGoal} options={allGoals.map(x => {
                  let option = new Option();
                  option.id = x.id.toString(); 
                  option.name = x.name.toString();
                  return option;
                })}
                />
                <br/>
                <RadioButton setValue={handleAlwaysShow} value={(questionnaire.alwaysShow ? "ja" : "nee")} radioButtonName="Interventie zonder locatie" options={["ja", "nee"]}></RadioButton>
                <br/>
              <SubmitButton fixed={true} value={isEdit ? "Opslaan" : "Voeg toe"}/>
            </form>
          </div>
        </div>

        <div className="column dateTimeSelect">
          <DateTimeSelect useTM={true} setDateTM={setDateTM} setTimeTM={setTimeTM} timeValueTM={timeTM} dateValueTM={dateTm} setDay={setDay} dayValue={day} timeValue={time} setTime={setTime} setDate={setStartDate} dateValue={startDate} selectName="dateTimeSelect" onClickButton={addDateTime}></DateTimeSelect>
          <br/>
          {questionnaire.dateTime &&
              questionnaire.dateTime.map(dateTime => {
                  return <TableRow onDeleteClick={() => removeDateTime(dateTime.id)} wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                    subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
              })
          }
        </div>

        <div className='column'>
          <div className="submit-button questionnaire-add-question">
            <button className='add-question-questionnaire-button' value="Voeg toe" onClick={() => addQuestion()}>Voeg vraag toe</button>
          </div>
          <div className="answers-container">
            {questionnaire.questions.map(question => {
              return (
                <div className='full-question-container'>
                  
                  <CloseModalButton onClick={() => deleteQuestion(question.id) }/>
                  <Input placeholderText={'Vraag'} inputName={question.id.toString()} 
                    value={question.name} onChange={handleQuestionChange}
                    inputType={'text'} inputLabel={'Vraag'} errors={[]}           
                  />
                  <RadioButton id={question.id} setValue={handleTypeChoice} value={question.questionType} radioButtonName="Type vraag" options={QuestionTypes}></RadioButton>
                  
                  { question.questionType != "Open vraag" &&
                    <div className='answers'>
                      {question.answers.map(answer => {
                        return (
                        <div className='add-row-questionnaire'>
                            <Input placeholderText={'Antwoord'} inputName={question.id.toString() + "-" + answer.id.toString()} 
                              value={answer.answerText} onChange={handleAnswerChange}
                              inputType={'text'} inputLabel={'Antwoord'} errors={[]}           
                            />
                            <button value = "Verwijder antwoord" className="trash trash-questionnaire-edit table-row-button" onClick={() => deleteAnswer(question.id, answer.id)}>
                              <img className="table-row-icon" src={TrashIcon} alt="verwijder" />
                            </button>
                        </div>
                      )})}
                      <div className="submit-button submit-button-questionnaire">
                        <button className='button-questionnaire' value="Voeg toe" onClick={() => addAnswer(question.id)}>Voeg antwoord toe</button>
                      </div>
                    </div>
                  }
                </div>
              )})}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionnaireEdit;
