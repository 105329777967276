import './UserDetail.css';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DetailTopSection from '../../components/detail-top-section/DetailTopSection';
import UserDTO from '../../dto/UserDTO';
import UserService from '../../services/user/UserService';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import GoalService from '../../services/goal/GoalService';
import GoalDTO from '../../dto/GoalDTO';
import Button from '../../components/button/Button';
import networkAdapter from "../../adapters/network/NetworkAdapterFactory";

function UserDetail() {
  const [user, setUser] = useState({} as UserDTO);

  useEffect(() => {
    const userService = new UserService();
    userService.loadOne(id)
    .then(val => {
      setUser(val);
    })
  }, [])

  const params = useParams();

  if(params.id === undefined) {
    return <div></div>
  }

  const id: number = Number.parseInt(params.id);

  if(!user || !user.role) {
    return <div></div>
  }

  const JSONToCSVConvertor = (JSONData: any, ReportTitle: string, ShowLabel: boolean) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      var length = 0
      var labelData;
      for(var index in arrData){
        var dataLength = Object.keys(arrData[index]).length;
        if(length < dataLength){
          length = dataLength;
          labelData = arrData[index];
        }
      }
      if (labelData === undefined) {
        alert("No data");
        return;
      }
      //This loop will extract the label from 1st index of on array
      for (var index in labelData) {
        //Now convert each value to string and com  ma-seprated
        row += index + ";";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += `${arrData[i][index] !== null ? arrData[i][index] : ''};`;
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("No data");
      return;
    }

    //Generate a file name
    var fileName = "Breadcrumbs_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.setAttribute('style', 'visibility:hidden');
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const exportUser = (id: number) => {
    networkAdapter
      .get("/breadcrumb/" + id)
      .then((res) => {
        // TODO: pastgoals opslaan
        const crumbs = res.data.map((e: any) => {
          // console.log(e);
          // e.userID = e.id;
          // delete e.id;
          // if (e.reaction.interventionId === 0) {
          //   delete e.reaction;
          // } else {
          //   e.franchise_id = e.reaction.franchiseId;
          //   e.franchise = e.reaction.franchise;
          //   e.area_id = e.reaction.areaId;
          //   e.area = e.reaction.area;
          //   e.location_id = e.reaction.locationId;
          //   e.location = e.reaction.location;
          //   e.goal_id = e.reaction.goalId;
          //   e.goal = e.reaction.goal;
          //   e.interventionId = e.reaction.interventionId;
          //   e.interventionName = e.reaction.interventionName;
          //   if(e.question !== null){
          //     e.question = e.reaction.questionDTO.questionText;
          //     e.answer = e.reaction.answers[0].answer;
          //   }
          //   delete e.reaction;
          // }

          var breadcrumb;
          console.log(e.reaction);
          if(e.reaction.interventionType == "questionnaire"){
            breadcrumb = {
              userID: e.id,
              lengtegraad: e.longitude,
              breedtegraad: e.latitude,
              tijd: e.time,
              franchise: e.reaction.franchise,
              locatie: e.reaction.location,
              doel: e.reaction.goal,
              interventieNaam: e.reaction.interventionName,
              type: e.reaction.interventionType,
              commando: e.reaction.questionDTO.questionText,
              vraag: e.reaction.questionDTO.questionText,
              antwoord: e.reaction.answers[0].answer
            }
          } else {
            breadcrumb = {
              userID: e.id,
              lengtegraad: e.longitude,
              breedtegraad: e.latitude,
              tijd: e.time,
              franchise: e.reaction.franchise,
              locatie: e.reaction.location,
              doel: e.reaction.goal,
              interventieNaam: e.reaction.interventionName,
              type: e.reaction.interventionType,
              commando: e.reaction.questionDTO.questionText,
              vraag: e.reaction.questionDTO.questionText,
              antwoord: e.reaction.answers[0].answer
            }
          }


          return breadcrumb;
        });
        JSONToCSVConvertor(crumbs, user.username, true);
      });
  };



  return (
    <div>
      <Breadcrumb lastItem={user.username}/>
      <DetailTopSection pageTitle={user.username} buttonTitle={'Wijzigen'} navigationLink={'/gebruikers/wijzigen/' + user.id} subheading={'Gebruiker'}/>
      <Button title={"Download breadcrumb"} onClick={(e) => exportUser(user.id)}/>
      <table className="user-detail-table">
        <tbody>
          <tr>
            <td className="table-min-width">Laat interventies zien:</td>
            <td>{user.showInterventions ? "ja" : "nee"}</td>
          </tr>
          <tr>
            <td className="table-min-width">Email:</td>
            <td>{user.email}</td>
          </tr>
          <tr>
            <td className="table-min-width">Rol:</td>
            <td>{user.role.name}</td>
          </tr>
          <tr>
            <td className="table-min-width">Doelstelling:</td>
            <td>{user.linkedGoal.name}</td>
          </tr>
          <tr>
            <td className="table-min-width">Naam:</td>
            <td>{user.name} {user.surname}</td>
          </tr>
          { user.birthDate != "" &&
            <tr>
              <td className="table-min-width">Geboortedatum:</td>
              <td>{new Intl.DateTimeFormat('en-GB', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",}).format(new Date(user.birthDate))}</td>
            </tr>
          }
          <tr>
            <td className="table-min-width">Geslacht:</td>
            <td>{user.gender}</td>
          </tr>
          <tr>
            <td className="table-min-width">Opleiding:</td>
            <td>{user.education}</td>
          </tr>
          <tr>
            <td className="table-min-width">Postcode:</td>
            <td>{user.zipCode}</td>
          </tr>
          <tr>
            <td className="table-min-width">Adres:</td>
            <td>{user.streetName} {user.houseNumber}</td>
          </tr>
          <tr>
            <td className="table-min-width">Antwoorden:</td>
          </tr>
          {user.answers.map((e) => <tr> {e.question + " : " + e.answer} </tr>)}
        </tbody>
      </table>
    </div>
  );
}

export default UserDetail;
