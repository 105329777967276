import InterventionDTO from "./InterventionDTO";

class FranchiseDTO {   
    id: number = 0;
    name: string = "";
    linkedInterventions : InterventionDTO[] = [];
    delay: number = 0;
    constructor(franchiseDTO?: FranchiseDTO) {
        Object.assign(this, franchiseDTO);
    }
}

export default FranchiseDTO;
