import './QuestionEdit.css';
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Input from '../../components/input/Input';
import SubmitButton from '../../components/submit-button/SubmitButton';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import QuestionDTO from '../../dto/QuestionDTO';
import QuestionService from '../../services/intervention/question/QuestionService';
import AnswerDTO from '../../dto/AnswerDTO';
import TrashIcon from '../../assets/icons/delete.svg';
import Select from '../../components/select/Select';
import GoalDTO from '../../dto/GoalDTO';
import Option from '../../components/select/Option';
import GoalService from '../../services/goal/GoalService';
import InterventionService from '../../services/intervention/InterventionService';
import InterventionDTO from '../../dto/InterventionDTO';
import TableRow from '../../components/tablerow/TableRow';
import DateTimeSelect from '../../components/dateTimeSelect/DateTimeSelect';
import DateTimeDTO from '../../dto/DateTimeDTO';
import RadioButton from '../../components/radioButton/RadioButton';
import QuestionTypes from '../../types/questionTypes'

const QuestionEdit : React.FC = () => {
  const [question, setQuestion] = useState({} as QuestionDTO);
  const [service, setService] = useState({} as QuestionService);
  const [errors, setErrors] = useState({} as any);
  const [allGoals, setAllGoals] = useState([] as GoalDTO[]);
  const [goal, setGoal] = useState(0 as number);
  const [currentGoal, setCurrentGoal] = useState({} as GoalDTO);
  const [intervention, setIntervention] = useState({} as InterventionDTO);
  const [startDate, setStartDate] = useState(null as unknown);
  const [time, setTime] = useState('' as string);
  const [day, setDay] = useState(String);
  const [type, setType] = useState(String);
  const [dateTimes, setDateTimes] = useState([] as DateTimeDTO[]);
  const [timeTM, setTimeTM] = useState('' as string);
  const [dateTm, setDateTM] = useState(null as unknown);
  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if(question.questionType == "Open vraag"){question.answers = []}

    if(!isEdit) {
      setQuestion({...question, goal_id: Number(id)});
      await service.create(question)
        .then(() => {
          toast.success("Vraag aangemaakt!");
          navigate("/interventies");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    } else {
      question.goal_id = currentGoal.id;
      await service.update(question)
        .then(response => {
          toast.success("Vraag bijgewerkt!");
          navigate("/interventies");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    }
  }

  const getCurrentGoal = () => {
    if(intervention.goal_id){
    const goalService = new GoalService();
      goalService.loadOne(intervention.goal_id)
      .then(val => {
        setCurrentGoal(val);
      })
    }
  };

  const getIntervention = () => {
    if(id){
      const InterService = new InterventionService();
      InterService.loadOne(id)
      .then(val => {
        setIntervention(val);
      })
    }
  };

  useEffect(() => {
    const questionService = new QuestionService();
    const goalService = new GoalService();
    getIntervention()
    setService(questionService)
    goalService.loadAll()
    .then(val => {
      setAllGoals(val);
    });
    if(!isEdit) {
      setQuestion(new QuestionDTO());
    } else {
      questionService.loadOne(id)
      .then(val => {
        setQuestion(val);
        setType(val.questionType);
      })
    }
  }, [])

  useEffect(() => {
    getCurrentGoal()
  }, [intervention.goal_id])

  const deleteAnswer = (id : number) : any => {
    question.answers = question.answers.filter(x => x.id !== id);
    setQuestion(Object.assign({}, question));
  }

  const addAnswer = () : any => {
    const newAnswer = new AnswerDTO();
    let id = 1;
    if(question.answers.length !== 0) {
      id = Math.max(...question.answers.map(x => x.id)) + 1;
    }
    newAnswer.id = id;
    question.answers.push(newAnswer);
    setQuestion(Object.assign({}, question));
  }

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setQuestion({...question, [e.target.id]: e.target.value})
  }

  const handleAnswerChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const answerId = e.target.id;
    const answer = question.answers.find(x => x.id.toString() === answerId);
    if(answer !== undefined) {
      answer.answerText = e.target.value;
      setQuestion(Object.assign({}, question));
    }
  }

  const updateGoal = (id:number) => {
    setGoal(id);
    currentGoal.id = id;
    setQuestion({...question, goal_id: Number(id)});
  }

  const addDateTime = (e: FormEvent) => {
    e.preventDefault();

    var dateArray : DateTimeDTO[] = [];
    if(question.dateTime){
      dateArray = question.dateTime;
    }

    const newDateTime : DateTimeDTO = new DateTimeDTO();
    if(day){newDateTime.day = day;}
    if(startDate && startDate instanceof Date){
      newDateTime.date = startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear();
    }
    if(time){newDateTime.time = time;}else{newDateTime.time = ""}
    if(dateTm && dateTm instanceof Date){newDateTime.dateTm = dateTm.getDate() + "-" + (dateTm.getMonth() + 1) + "-" + dateTm.getFullYear();}
    if(timeTM){newDateTime.timeTm = timeTM;}
    newDateTime.id = question.dateTime ? question.dateTime.length + 1 : 0;
    dateArray.push(newDateTime);

    const newQuestion : QuestionDTO = new QuestionDTO();
    newQuestion.goal_id = question.goal_id;
    newQuestion.id = question.id;
    newQuestion.name = question.name;
    newQuestion.dateTime = dateArray;
    newQuestion.answers = question.answers;
    newQuestion.question = question.question;
    newQuestion.questionType = question.questionType;
    newQuestion.alwaysShow = question.alwaysShow;

    setQuestion(newQuestion);
  }

  const handleTypeChoice = (type: string) => {
    question.questionType = type;
    setType(type);
  }

  const removeDateTime = (id:number) => {
    const newQue : QuestionDTO = new QuestionDTO(question);
    if(newQue.dateTime){
      newQue.dateTime = newQue.dateTime.filter(x => x.id !== id);
    }
    setQuestion(newQue);
  }

  const handleAlwaysShow = (option: string) => {
    console.log(option);
    question.alwaysShow = (option == "ja" ? true : false);
    setQuestion({...question, alwaysShow: (option == "ja" ? true : false)});
  }

  const id: number = Number.parseInt(params.id === undefined ? "0" : params!.id);
  const isEdit: boolean = id !== 0;

  if(!question || !question.answers) {
    return <div></div>
  }


  return (
    <div>
      <Breadcrumb lastItem={question.name} itemsToRemove={["vraag"]}/>
      <h2>{isEdit ? question.name + " Wijzigen" : "Vraag aanmaken"}</h2>
      <div className='flex-container'>
        <div className='column'>
          <form onSubmit={onSubmit}>
            <Input placeholderText={'Naam'} inputName={'name'} inputType={'text'} inputLabel={'Naam'} onChange={handleChange} value={question.name} errors={errors.name}/>
            <br/>
            <Input placeholderText={'Vraag'} inputName={'question'} inputType={'text'} inputLabel={'Vraag'} onChange={handleChange} value={question.question} errors={errors.question}/>
            <br/>
            <RadioButton setValue={handleTypeChoice} value={question.questionType} radioButtonName="Type vraag" options={QuestionTypes}></RadioButton>
            <br/>
            <Select dots={false} placeholderText={currentGoal.name} value={goal.toString()} selectName={'Goalid'} selectLabel={'Doelstelling'} onChange={updateGoal} options={allGoals.map(x => {
              let option = new Option();
              option.id = x.id.toString(); 
              option.name = x.name.toString(); 
              return option;
            })}
            />
          <br/>
            <RadioButton setValue={handleAlwaysShow} value={(question.alwaysShow ? "ja" : "nee")} radioButtonName="Interventie zonder locatie" options={["ja", "nee"]}></RadioButton>
            <br/>
            <SubmitButton fixed={true} value={isEdit ? "Opslaan" : "Voeg toe"}/>
          </form>
        </div>
        { type != "Open vraag" &&
          <div className='column answers'>
              {question.answers.map((answer, index) => {
                return (
                  <div>
                    <Input placeholderText={'Antwoord'} inputName={answer.id.toString()} 
                      value={answer.answerText} onChange={handleAnswerChange}
                      inputType={'text'} inputLabel={'Antwoord ' + (index + 1)} errors={[]}           
                    />
                    <button value = "Verwijder antwoord" className="trash trash-intervention-edit table-row-button" onClick={() => deleteAnswer(answer.id)}>
                      <img className="table-row-icon" src={TrashIcon} alt="verwijder" />
                    </button>
                  </div>
                )})}
            <div className="submit-button-intervention-question">
                <button value="Voeg toe" onClick={() => addAnswer()}>Voeg antwoord toe</button>
            
            </div>
          </div>
        }
        <div className="column dateTimeSelect">
          <DateTimeSelect useTM={true} setDateTM={setDateTM} setTimeTM={setTimeTM} timeValueTM={timeTM} dateValueTM={dateTm} setDay={setDay} dayValue={day} timeValue={time} setTime={setTime} setDate={setStartDate} dateValue={startDate} selectName="dateTimeSelect" onClickButton={addDateTime}></DateTimeSelect>
          <br/>
          {question.dateTime &&
              question.dateTime.map(dateTime => {
                  return <TableRow onDeleteClick={() => removeDateTime(dateTime.id)} wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                    subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
              })
          }
        </div>
      </div>

    </div>
  );
}

export default QuestionEdit;
