import LocationDTO from "../../dto/LocationDTO";
import DateTimeDTO from "../../dto/DateTimeDTO";

class LocationUpdateRequestDTO {
    areaId: number = 0;
    franchiseId: number = 0;
    latitude: number = 0;
    longitude: number = 0;
    name: string = "";
    radius: number = 0;
    linkedInterventions: number[] = [];
    dateTime: DateTimeDTO[] = [];

    constructor(locationDTO: LocationDTO) {
        this.areaId = locationDTO.areaId;
        this.franchiseId = locationDTO.franchiseId;
        this.latitude = locationDTO.latitude;
        this.longitude = locationDTO.longitude;
        this.name = locationDTO.name;
        this.radius = locationDTO.radius;
        this.linkedInterventions = locationDTO.linkedInterventions.map(x => {return x.id});
        if(locationDTO.dateTime){
            this.dateTime = locationDTO.dateTime;
        }
    };
}

export default LocationUpdateRequestDTO;
