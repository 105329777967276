import './QuestionDetail.css';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DetailTopSection from '../../../components/detail-top-section/DetailTopSection';
import QuestionDTO from '../../../dto/QuestionDTO';
import QuestionService from '../../../services/intervention/question/QuestionService';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import InterventionDTO from '../../../dto/InterventionDTO';
import InterventionService from '../../../services/intervention/InterventionService';
import GoalDTO from '../../../dto/GoalDTO';
import GoalService from '../../../services/goal/GoalService';
import TableRow from '../../../components/tablerow/TableRow';

function QuestionDetail() {
  const [question, setQuestion] = useState({} as QuestionDTO);
  const [intervention, setIntervention] = useState({} as InterventionDTO);
  const [goal, setGoal] = useState({} as GoalDTO);

  const getQuestion = () => {
    const service = new QuestionService()
    service.loadOne(id)
    .then(val => {
      setQuestion(val);
    })
  };

  const getGoal = () => {
    const goalService = new GoalService();
    if(intervention.goal_id){
      goalService.loadOne(intervention.goal_id)
      .then(val => {
        setGoal(val);
      })
    }
  };

  const getIntervention = () => {
    const InterService = new InterventionService();
    InterService.loadOne(id)
    .then(val => {
      setIntervention(val);
    })
  };

  const params = useParams();
  useEffect(() => {
    getIntervention()
    getQuestion()
  }, [])

  useEffect(() => {
    getGoal()
  }, [intervention.goal_id])

  if(params.id === undefined) {
    return <div></div>
  }

  const id: number = Number.parseInt(params.id);
  
  if(!question || !question.answers) {
    return <div></div>
  }

  return (
    <div> 
      <Breadcrumb lastItem={question.question}/>
      <DetailTopSection pageTitle={question.name ?? ""} buttonTitle={'Wijzigen'} navigationLink={'/interventies/vraag/wijzigen/' + question.id} subheading={'Interventie'}/>
      <table className="question-detail-table">
        <tbody>
          <tr>
            <td className="table-min-width">Interventie</td>
            <td>Vraag</td>
          </tr>
          <tr>
            <td className="table-min-width">Doelstelling</td>
            <td>{goal.name}</td>
          </tr>
          <tr>
            <td className="table-min-width">Type vraag</td>
            <td>{question.questionType}</td>
          </tr>
          <tr>
            <td className="table-min-width">Vraag</td>
            <td>{question.question}</td>
          </tr>
          <tr>
            <td className="table-min-width">Interventie zonder locatie</td>
            <td>{question.alwaysShow ? "Ja" : "Nee"}</td>
          </tr>
        </tbody> 
      </table>
      { question.questionType != "Open vraag" &&
      <div className="questions">
        <h4>Antwoorden</h4>
        {
        question.answers.map(answer => {
          return <p id={answer.id.toString()}>{answer.answerText}</p>
        })
        }
      </div>
      }
        <div className="dateTimes">
          <h3>interventie tijden</h3>
          {question.dateTime &&
            question.dateTime.length > 0 ?
            question.dateTime.map(dateTime => {
              return <TableRow wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
            })
            : <h5>Geen specifieke tijden</h5>
          }
        </div>
    </div>  
  );
}

export default QuestionDetail;
