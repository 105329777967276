import './QuestionnaireDetail.css';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DetailTopSection from '../../../components/detail-top-section/DetailTopSection';
import QuestionnaireDTO from '../../../dto/QuestionnaireDTO';
import QuestionnaireService from '../../../services/intervention/questionnaire/QuestionnaireService';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import GoalDTO from '../../../dto/GoalDTO';
import GoalService from '../../../services/goal/GoalService';
import TableRow from '../../../components/tablerow/TableRow';

function QuestionnaireDetail() {
  const [questionnaire, setQuestionnaire] = useState({} as QuestionnaireDTO);
  const [goal, setGoal] = useState({} as GoalDTO);

  const getQuestionnaire = () => {
    const Service = new QuestionnaireService();
    Service.loadOne(id)
    .then(val => {
      setQuestionnaire(val);
    })
  };

  const getGoal = () => {
    const goalService = new GoalService();
    if(questionnaire.goal_id){
      goalService.loadOne(questionnaire.goal_id)
      .then(val => {
        setGoal(val);
      })
    }
  };

  const params = useParams();
  useEffect(() => {
    getQuestionnaire()
  }, [])

  useEffect(() => {
    getGoal()
  }, [questionnaire.goal_id])

  if(params.id === undefined) {
    return <div></div>
  }

  const id: number = Number.parseInt(params.id);

  if(!questionnaire || !questionnaire.questions) {
    return <div></div>
  }

  return (
    <div>
      <Breadcrumb lastItem={questionnaire.name} itemsToRemove={["vragenlijst"]}/>
      <DetailTopSection pageTitle={questionnaire.name} buttonTitle={'Wijzigen'} navigationLink={'/interventies/vragenlijst/wijzigen/' + questionnaire.id} subheading={'Vragenlijst'}/>
      <table className="questionnaire-detail-table">
        <tbody>
          <tr>
            <td className="table-min-width">Interventie</td>
            <td>Vraag</td>
          </tr>
          <tr>
            <td className="table-min-width">Doelstelling</td>
            <td>{goal.name}</td>
          </tr>
          <tr>
            <td className="table-min-width">Interventie zonder locatie</td>
            <td>{questionnaire.alwaysShow ? "Ja" : "Nee"}</td>
          </tr>
        </tbody>
      </table>
      <div className="questionnaires">
        {
          questionnaire.questions.map((question, index) => {
            return (
              <div>
                <h4>{"Vraag " + (index + 1) + ": " + question.name}</h4>
                {
                  question.questionType !== "Open vraag" && question.answers.map(answer => {
                    return (
                      <div>
                        <p>{answer.answerText}</p>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
      <div className="dateTimes">
          <h3>interventie tijden</h3>
          {questionnaire.dateTime &&
            questionnaire.dateTime.length > 0 ?
            questionnaire.dateTime.map(dateTime => {
              return <TableRow wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
            })
            : <h5>Geen specifieke tijden</h5>
          }
        </div>
    </div>
  );
}

export default QuestionnaireDetail;
