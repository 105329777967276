import './FranchiseDetail.css';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DetailTopSection from '../../components/detail-top-section/DetailTopSection';
import FranchiseDTO from '../../dto/FranchiseDTO';
import FranchiseService from '../../services/franchise/FranchiseService';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import TableRow from '../../components/tablerow/TableRow';

function FranchiseDetail() {
  const [franchise, setFranchise] = useState({} as FranchiseDTO);
  const [service, setService] = useState({} as FranchiseService);

  const params = useParams();
  useEffect(() => {
    const franchiseService = new FranchiseService();
    setService(franchiseService)
    franchiseService.loadOne(id)
    .then(val => {
      setFranchise(val);
    })
  }, [])

  if(params.id === undefined) {
    return <div></div>
  }

  const id: number = Number.parseInt(params.id);

  return (
    <div>
      <Breadcrumb lastItem={franchise.name}/>
      <DetailTopSection pageTitle={franchise.name} buttonTitle={'Wijzigen'} navigationLink={'/franchises/wijzigen/' + franchise.id} subheading={'Franchises'}/>
      <table>
        <tr>
              <td className="table-min-width">Delay</td>
              <td>{franchise.delay}</td>
        </tr>
      </table>
      <h4>Interventies</h4>
      {franchise.linkedInterventions &&
        franchise.linkedInterventions.length > 0 ?
          franchise.linkedInterventions.map(intervention => {
            return <TableRow title={intervention.name}/>
          })
        : <p>Geen Interventies</p> 
        
      }
    </div>
  );
}

export default FranchiseDetail;
