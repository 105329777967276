import AnswerDTO from "./AnswerDTO";
import DateTimeDTO from "./DateTimeDTO";

class QuestionDTO {
    id: number = 0;
	name: string = "";
	question: string = "";
	answers: AnswerDTO[] = [];
    goal_id: number = 0;
    dateTime?: DateTimeDTO[] = [];
    alwaysShow: boolean = false;
    questionType: string = "";
    constructor(questionDTO?: QuestionDTO) {
        Object.assign(this, questionDTO);
    }
}

export default QuestionDTO;
