import DateTimeDTO from "./DateTimeDTO";

class InterventionDTO {
    id: number = 0;
    name: string = "";
    type: string = "";
    goal_id: number = 0;
    dateTime?: DateTimeDTO[] = [];
    alwaysShow: boolean = false;

    constructor(InterventionDTO?: InterventionDTO) {
        Object.assign(this, InterventionDTO);
    }
}

export default InterventionDTO;
