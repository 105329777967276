import CommandDTO from "../../../dto/CommandDTO";
import DateTimeDTO from "../../../dto/DateTimeDTO";

class CommandUpdateRequestDTO {
    id: number = 0;
    name: string = "";
    commandText: string = "";
    goal_id: number = 0;
    dateTime: DateTimeDTO[] = [];
    alwaysShow: boolean = false;
    constructor(commandDTO: CommandDTO) {
        this.id = commandDTO.id;
        this.name = commandDTO.name;
        this.commandText = commandDTO.commandText;
        this.goal_id = commandDTO.goal_id;
        this.alwaysShow = commandDTO.alwaysShow;
        if(commandDTO.dateTime){
            this.dateTime = commandDTO.dateTime;
        }
    };
}

export default CommandUpdateRequestDTO;
