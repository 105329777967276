import networkAdapter from "../../adapters/network/NetworkAdapterFactory";
import ScheduleDTO from "../../dto/ScheduleDTO";
import LoginService from "../login/LoginService";


class ScheduleService {

    loginService : LoginService = new LoginService();
    addSchedule(schedule: ScheduleDTO, roleId: number) {
        return networkAdapter.post("schedules/" + this.loginService.getLocalStorage("id") + "/" + roleId, schedule);
    }

    getSchedule(roleId: number) {
        return networkAdapter.get("schedules/" + roleId);
    }

    deleteSchedule(id : number) {
        return networkAdapter.delete("schedules/" + id);
    }

    getDateSubString(dateDb : string) {
        return dateDb.substring(0, 10);
    }

    getTimeSubString(dateDb : string) {
        return dateDb.substring(11, 16);
    }

    dateToDayString(date: Date) {
        switch(date.getDay()) {
            case 0:
                return "Zondag";
            case 1: 
                return "Maandag";
            case 2:
                return "Dinsdag";
            case 3:
                return "Woensdag";
            case 4:
                return "Donderdag";
            case 5:
                return "Vrijdag";
            case 6:
                return "Zaterdag";
    }
}

    
}

export default ScheduleService;