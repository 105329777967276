import React from 'react';
import Button from '../button/Button';
import './RadioButton.css';
import { useNavigate } from "react-router-dom";
import { setOptions } from 'leaflet';
import { useState } from 'react';

interface Props {
    radioButtonName: String,
    options: String[],
    setValue: Function,
    value: string,
    disabled?: boolean,
    id?: number
}

function RadioButton({radioButtonName, options, setValue, value, disabled = false, id}: Props ) {

    const navigate = useNavigate();

    return (
        <div className="radioButtons">
            <h4>{radioButtonName}</h4>
            {options.map(option => (
                <div className="radioBtn">
                    <label>
                        { option == value ? 
                            
                            <input type="radio" checked name={radioButtonName + (id?.toString() ?? "")} onChange={id ? () => setValue(option, id) : () => setValue(option)} />
                        :
                            <input type="radio" name={radioButtonName + (id?.toString() ?? "")} onChange={id ? () => setValue(option, id) : () => setValue(option)} />
                        }
                        {option}
                    </label>
                </div>
            ))}
        </div>
    );
}

export default RadioButton;