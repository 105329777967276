import './FranchiseEdit.css';
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Input from '../../components/input/Input';
import SubmitButton from '../../components/submit-button/SubmitButton';
import FranchiseDTO from '../../dto/FranchiseDTO';
import FranchiseService from '../../services/franchise/FranchiseService';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import InterventionDTO from '../../dto/InterventionDTO';
import Select from '../../components/select/Select';
import Option from '../../components/select/Option';
import InterventionService from '../../services/intervention/InterventionService';
import TableRow from '../../components/tablerow/TableRow';


const FranchiseEdit : React.FC = () => {
  const [franchise, setFranchise] = useState({} as FranchiseDTO);
  const [service, setService] = useState({} as FranchiseService);
  const [errors, setErrors] = useState({} as any);
  const [allInterventions, setAllInterventions] = useState([] as InterventionDTO[]);
  const [selectedIntervention, setSelectedIntervention] = useState(0 as Number);

  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if(!isEdit) {
      await service.create(franchise)
        .then(() => {
          toast.success("Franchise aangemaakt!");
          navigate("/franchises");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    } else {
      await service
        .update(franchise)
        .then(response => {
          toast.success("Franchise bijgewerkt!");
          navigate("/franchises");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    }
  }

  const getAllInterventions = () => {
    const interventionService = new InterventionService();
    interventionService
      .loadAll()
      .then(interventions => {
        setAllInterventions(interventions);
    })
  };

  useEffect(() => {
    const franchiseService = new FranchiseService();
    setService(franchiseService)
    if(!isEdit) {
      setFranchise(new FranchiseDTO())
    } else {
      franchiseService.loadOne(id)
      .then(val => {
        setFranchise(val);
      })
    }

    getAllInterventions()
  }, [])

  const id: number = Number.parseInt(params.id === undefined ? "0" : params!.id);
  const isEdit: boolean = id !== 0;

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setFranchise({...franchise, [e.target.id]: e.target.value})
  }

  const changeSelectedIntervention = (id:string) => {
    setSelectedIntervention(Number(id));
  }

  const removeIntervention = (id:number) => {
    const newFran : FranchiseDTO = new FranchiseDTO(franchise);
    newFran.linkedInterventions = newFran.linkedInterventions.filter(x => x.id !== id);

    setFranchise(newFran);
  }

  const addIntervention = (e: FormEvent) : void => {
    e.preventDefault();
    var select = document.getElementById('new-intervention') as any;
    var value = select.options[select.selectedIndex].value;
    if(value != 0) {
      const newFran : FranchiseDTO = new FranchiseDTO(franchise);
      newFran.linkedInterventions.push(allInterventions.find(x => x.id === Number(value)) as InterventionDTO);
  
      setFranchise(newFran);
    }
  }

  return (
    <div>
      <Breadcrumb lastItem={franchise.name}/>
      <h2>{isEdit ? franchise.name + " Wijzigen" : "Franchise aanmaken"}</h2>
      <div className="fields-row">
        <div className="column">
          <form onSubmit={onSubmit}>
            <Input placeholderText={'Naam'} inputName={'name'} inputType={'text'} inputLabel={'Naam'} onChange={handleChange} value={franchise.name} errors={errors.name}/>
            <br/>
            <Input placeholderText={'Delay'} inputName={'delay'} inputType={'number'} inputLabel={'Delay'} onChange={handleChange} value={franchise.delay} errors={errors.delay}/>
            <br/>
            <SubmitButton fixed={true} value={isEdit ? "Opslaan" : "Voeg toe"}/>
          </form>
        </div>
          <div className="column interventions">
            <form className="add-row" onSubmit={addIntervention}>
              <Select 
                placeholderText={'Kies een interventie'} 
                selectName={'new-intervention'} 
                onChange={changeSelectedIntervention} 
                selectLabel={'Voeg interventie toe'} 
                value={selectedIntervention.toString()}
                options={allInterventions.filter(x => franchise.linkedInterventions.find(y => y.id === x.id) === undefined).map(intervention => {
                  let option = new Option();
                  option.id = intervention.id.toString();
                  option.name = intervention.name;

                  return option;
                })
                }
                />
              <div className="add-button">
                <SubmitButton value="Voeg toe"/>
              </div>
            </form>
            
            {franchise.linkedInterventions &&
                franchise.linkedInterventions.map(intervention => {
                  return <TableRow title={intervention.name} onDeleteClick={() => removeIntervention(intervention.id)} />
                })
            }
          </div>
        </div>
    </div>
  );
}

export default FranchiseEdit
