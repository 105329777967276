import './LocationEdit.css';
import '../../services/location/LocationService';
import { useState, useEffect, FormEvent } from 'react';
import LocationService from '../../services/location/LocationService';
import LocationDTO from '../../dto/LocationDTO';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Input from '../../components/input/Input';
import SubmitButton from '../../components/submit-button/SubmitButton';
import AreaDTO from '../../dto/AreaDTO';
import InterventionDTO from '../../dto/InterventionDTO';
import InterventionService from '../../services/intervention/InterventionService';
import TableRow from '../../components/tablerow/TableRow';
import AreaService from '../../services/AreaService';
import Select from '../../components/select/Select';
import Option from '../../components/select/Option';
import FranchiseService from '../../services/franchise/FranchiseService';
import FranchiseDTO from '../../dto/FranchiseDTO';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { Circle } from 'leaflet';
import Map from "../../components/map/Map"
import createCircle from '../../adapters/circle/CircleFactory';
import GoalService from '../../services/goal/GoalService';
import DateTimeSelect from '../../components/dateTimeSelect/DateTimeSelect';
import DateTimeDTO from '../../dto/DateTimeDTO'; 

function LocationEdit() {
  const [location, setLocation] = useState({} as LocationDTO);
  const [selectedIntervention, setSelectedIntervention] = useState(0 as Number);
  const [allAreas, setAllAreas] = useState([] as AreaDTO[]);
  const [allInterventions, setAllInterventions] = useState([] as InterventionDTO[]);
  const [allFranchises, setAllFranchises] = useState([] as FranchiseDTO[]);
  const [service, setService] = useState({} as LocationService);
  const [errors, setErrors] = useState({} as any);
  const [circles, setCircles] = useState([] as Circle[]);
  const [mapKey, setMapKey] = useState(1 as number);
  const [startDate, setStartDate] = useState(null as unknown);
  const [time, setTime] = useState('' as string);
  const [day, setDay] = useState(String);
  const [dateTimes, setDateTimes] = useState([] as DateTimeDTO[]);
  const [timeTM, setTimeTM] = useState('' as string);
  const [dateTm, setDateTM] = useState(null as unknown);

  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if(!isEdit) {
      await service.create(location)
        .then(() => {
          toast.success("Locatie aangemaakt!");
          navigate("/locaties");
        }).catch(err => {
          setErrors(err.response.data);
          console.log(err.response.data)
          return;
        });
    } else {
      await service.update(location)
      .then(() => {
        toast.success("Locatie bijgewerkt!");
        navigate("/locaties");
      }).catch(err => {
        setErrors(err.response.data);
        return;
      });
    }
  }

  useEffect(() => {
    const locationService = new LocationService();
    const interventionService = new InterventionService();
    const areaService = new AreaService();
    const franchiseService = new FranchiseService();
    setService(locationService)
    interventionService
      .loadAll()
      .then(interventions => {
        setAllInterventions(interventions);
      })
    areaService
      .loadAll()
      .then(areas => {
        setAllAreas(areas);
      });
    franchiseService
      .loadAll()
      .then(franchises => {
        setAllFranchises(franchises);
      });
    if(!isEdit) {
      let locDTO: LocationDTO = new LocationDTO();
      locDTO.area = new AreaDTO();
      setLocation(locDTO);
    } else {
      locationService.loadOne(id)
      .then(val => {
        setLocation(val);
      })
    }
  }, [])

  const id: number = Number.parseInt(params.id === undefined ? "0" : params!.id);
  const isEdit: boolean = id !== 0;

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setLocation({...location, [e.target.id]: e.target.value});
  }

  //This effect is needed because we sometimes want to update the circles whenever the location updates
  useEffect(() => {
    setCircles(getCircles(location));
    setMapKey(mapKey + 1);
  }, [location])

  const getCircles = (location : LocationDTO) : Circle[] => {
    const tempCircles = [] as Circle[];

    if(location.longitude === undefined) {
      return [];
    }

    tempCircles.push(createCircle(location.longitude, location.latitude, location.radius, "blue"));
    tempCircles.push(createCircle(location.area.longitude, location.area.latitude, location.area.radius, "red"));

    return tempCircles;
  }

  const updateArea = (id:string) => {
    const newLoc = {...location, "areaId": Number(id)};

    const newArea = allAreas.find(x => x.id == Number(id));

    newLoc.area = newArea === undefined ? newLoc.area : newArea;
    
    setLocation(newLoc);
  }

  const updateFranchise = (id:string) => {
    setLocation({...location, "franchiseId": Number(id)});
  }

  const changeSelectedIntervention = (id:string) => {
    setSelectedIntervention(Number(id));
  }

  const removeIntervention = (id:number) => {
    const newLoc : LocationDTO = new LocationDTO(location);
    newLoc.linkedInterventions = newLoc.linkedInterventions.filter(x => x.id !== id);

    setLocation(newLoc);
  }

  const addIntervention = (e: FormEvent) : void => {
    e.preventDefault();
    var select = document.getElementById('new-intervention') as any;
    var value = select.options[select.selectedIndex].value;
    if(value != 0) {
      const newLoc : LocationDTO = new LocationDTO(location);
      newLoc.linkedInterventions.push(allInterventions.find(x => x.id === Number(value)) as InterventionDTO);
  
      setLocation(newLoc);
    }
  }

  const addDateTime = (e: FormEvent) => {
    e.preventDefault();

    var dateArray : DateTimeDTO[] = [];
    if(location.dateTime){
      dateArray = location.dateTime;
    }
    

    const newDateTime : DateTimeDTO = new DateTimeDTO();
    if(startDate && startDate instanceof Date){
      newDateTime.date = startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear();
    }
    if(time){newDateTime.time = time;}
    if(day){newDateTime.day = day;}
    if(dateTm && dateTm instanceof Date){newDateTime.dateTm = dateTm.getDate() + "-" + (dateTm.getMonth() + 1) + "-" + dateTm.getFullYear();}
    if(timeTM){newDateTime.timeTm = timeTM;}
    newDateTime.id = location.dateTime ? location.dateTime.length + 1 : 1;
    dateArray.push(newDateTime);

    const newLocation : LocationDTO = new LocationDTO();
    newLocation.id = location.id;
    newLocation.name = location.name;
    newLocation.area = location.area;
    newLocation.areaId = location.areaId;
    newLocation.franchise = location.franchise;
    newLocation.franchiseId = location.franchiseId;
    newLocation.latitude = location.latitude;
    newLocation.linkedInterventions = location.linkedInterventions;
    newLocation.longitude = location.longitude;
    newLocation.radius = location.radius;
    newLocation.dateTime = dateArray;

    setLocation(newLocation);

  }
  
  const removeDateTime = (id:number) => {
    const newLoc : LocationDTO = new LocationDTO(location);
    if(newLoc.dateTime){
      newLoc.dateTime = newLoc.dateTime.filter(x => x.id !== id);
    }
    setLocation(newLoc);
  }

  if(!allInterventions || !location || !location.linkedInterventions || !allAreas) {
    return null;
  }

  return (
    <div>
      <Breadcrumb lastItem={location.name}/>
      <h2>{isEdit ? location.name + " Wijzigen" : "Locatie aanmaken"}</h2>
      <div className="flex-container">
      <form onSubmit={onSubmit} className='form-edit'>
          <Input placeholderText={'Naam'} inputName={'name'} inputType={'text'} inputLabel={'Naam'} onChange={handleChange} value={location.name} errors={errors.name}/>
          <br/>
          <Select placeholderText={'Kies een gebied'} value={location.areaId.toString()} selectName={'areaId'} selectLabel={'Gebied'} onChange={updateArea} options={allAreas.map(x => {
            let option = new Option();
            option.id = x.id.toString();
            option.name = x.name;

            return option;
          })}
          width={"large"}
          />
          <br/>
          <Select placeholderText={'Kies een franchise'} value={location.franchiseId.toString()} selectName={'franchiseId'} selectLabel={'Franchise'} onChange={updateFranchise} options={allFranchises.map(x => {
            let option = new Option();
            option.id = x.id.toString();
            option.name = x.name;

            return option;
          })}
          width={"large"}
          />
          <br/>
          <Input placeholderText={'Lengtegraad'} inputName={'longitude'} inputType={'number'} inputLabel={'Lengtegraad'} onChange={handleChange} value={location.longitude === 0 ? "" : location.longitude} errors={errors.longitude}/>
          <br/>
          <Input placeholderText={'Breedtegraad'} inputName={'latitude'} inputType={'number'} inputLabel={'Breedtegraad'} onChange={handleChange} value={location.latitude === 0 ? "" : location.latitude} errors={errors.latitude}/>
          <br/>
          <Input placeholderText={'Straal in meters'} inputName={'radius'} inputType={'number'} inputLabel={'Straal'} onChange={handleChange} value={location.radius === 0 ? "" : location.radius} errors={errors.radius}/>
          <br/>
          <SubmitButton fixed={true} value={isEdit ? "Opslaan" : "Voeg toe"}/>
      </form>
      <Map key={mapKey} viewCoords={[location.latitude, location.longitude]} viewZoom={15} circles={circles}></Map>
      <div className="column interventions">
        <form className="add-row" onSubmit={addIntervention}>
          <Select 
            placeholderText={'Kies een interventie'} 
            selectName={'new-intervention'} 
            onChange={changeSelectedIntervention} 
            selectLabel={'Voeg interventie toe'} 
            value={selectedIntervention.toString()}
            options={allInterventions.filter(x => location.linkedInterventions.find(y => y.id === x.id) === undefined).map(intervention => {
              let option = new Option();
              option.id = intervention.id.toString();
              option.name = intervention.name;

              return option;
            })
            }
            />
          <div className="add-button">
            <SubmitButton value="Voeg toe"/>
          </div>
        </form>
        {location.linkedInterventions.map(intervention => {
          return <TableRow navigationLink={"/interventies/" + intervention.type + "/" + intervention.id} title={intervention.name} onDeleteClick={() => removeIntervention(intervention.id)} />
        })}
        </div>
        <div className="column dateTimeSelect">
          <DateTimeSelect useTM={true} setDateTM={setDateTM} setTimeTM={setTimeTM} timeValueTM={timeTM} dateValueTM={dateTm} setDay={setDay} dayValue={day} timeValue={time} setTime={setTime} setDate={setStartDate} dateValue={startDate} selectName="dateTimeSelect" onClickButton={addDateTime}></DateTimeSelect>
          <br/>
          {location.dateTime &&
                location.dateTime.map(dateTime => {
                  return <TableRow onDeleteClick={() => removeDateTime(dateTime.id)} wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                    subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
                })
          }
        </div>
      </div>
    </div>
  );
}

export default LocationEdit
