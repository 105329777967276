class DateTimeDTO {
    date: string = ""
    time: string = "";
    day: string = "";
    id: number = 0;
    dateTm?: string = "";
    timeTm?: string = "";
    constructor(dateTimeDTO?: DateTimeDTO) {
        Object.assign(this, dateTimeDTO);
    }
}

export default DateTimeDTO;
