import './RoleEdit.css';
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Input from '../../components/input/Input';
import SubmitButton from '../../components/submit-button/SubmitButton';
import RoleDTO from '../../dto/RoleDTO';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import RoleService from '../../services/role/RoleService';
import PermissionDTO from '../../dto/PermissionDTO';
import ScheduleDTO from '../../dto/ScheduleDTO';
import DateTimeSelect from '../../components/dateTimeSelect/DateTimeSelect';
import TableRow from '../../components/tablerow/TableRow';
import ScheduleService from '../../services/schedule/ScheduleService';
import RadioButton from '../../components/radioButton/RadioButton';

const RoleEdit : React.FC = () => {
  const [role, setRole] = useState({} as RoleDTO);
  const [permissions, setPermissions] = useState([] as PermissionDTO[]);
  const [service, setService] = useState({} as RoleService);
  const [errors, setErrors] = useState({} as any);
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState('' as string);
  const [day, setDay] = useState(String);
  const [schedules, setSchedules] = useState([] as ScheduleDTO[]);
  const [timeTM, setTimeTM] = useState('' as string);
  const [dateTm, setDateTM] = useState(new Date());
  const [repeat, setRepeat] = useState(Boolean);

  const params = useParams();
  const navigate = useNavigate();
  const scheduleService = new ScheduleService;


  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if(!isEdit) {
      await service.create(role)
        .then(() => {
          toast.success("Rol aangemaakt!");
          navigate("/rollen");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    } else {
      await service.update(role)
        .then(response => {
          toast.success("Rol bijgewerkt!");
          navigate("/rollen");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    }
  }

  const addDateTime = () => {
    const schedule = new ScheduleDTO();
    schedule.repeat = repeat;
    schedule.date = (startDate.getFullYear() + "-" + ('0' + (startDate.getMonth()+1)).slice(-2)+ "-" + ('0' + startDate.getDate()).slice(-2));
    schedule.from = schedule.date + "T" + time + ":00";
    schedule.to = schedule.date + "T" + timeTM  + ":00";
    scheduleService.addSchedule(schedule, role.id);
    window.location.reload();
  }

  const deleteSchedule = async (id : number) => {
    scheduleService.deleteSchedule(id);
    await scheduleService.getSchedule(id).then(response => {
      setSchedules(response.data);
    });
  }


  useEffect(() => {
    const roleService = new RoleService();
    setService(roleService);
    roleService.loadPermissions().then(data => {
      setPermissions(data);
    });
    scheduleService.getSchedule(id).then(response => {
      setSchedules(response.data);
    });
    if(!isEdit) {
      setRole(new RoleDTO())
    } else {
      roleService.loadOne(id)
      .then(val => {
        setRole(val);
      })
    }
  }, [])

  const id: number = Number.parseInt(params.id === undefined ? "0" : params!.id);
  const isEdit: boolean = id !== 0;

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setRole({...role, [e.target.id]: e.target.value})
  }

  const changePermission = (key:string) => {
    if(role.allowedPermissions.find(x => x.key === key) === undefined) {
      role.allowedPermissions.push(permissions.find(x => x.key === key)!);
    } else {
      role.allowedPermissions = role.allowedPermissions.filter(x => x.key !== key);
    }

    setRole(Object.assign({}, role));
  }

  if(!role || !role.allowedPermissions || !permissions) {
    return <div></div>
  }

  const HandleRepeatChoice = (option: string) => {
    setRepeat((option == "ja" ? true : false));
  }
  

  return (
    <div>
      <Breadcrumb lastItem={role.name}/>
      <h2>{isEdit ? role.name + " Wijzigen" : "Rol aanmaken"}</h2>
      <div className="fields-row">
        <div className="column">
          <form onSubmit={onSubmit}>
            <Input placeholderText={'Naam'} inputName={'name'} inputType={'text'} inputLabel={'Naam'} onChange={handleChange} value={role.name} errors={errors.name}/>
            <br/>
            <h4 className="input-label">Permissies</h4>
            {permissions.map(x => {
              return <div key={x.key} className="permission">
                <input 
                  key={x.key} 
                  type="checkbox" 
                  onChange={() => changePermission(x.key)} 
                  checked={role.allowedPermissions.find(y => y.key === x.key) !== undefined}
                />
                <p>{x.display}</p>
              </div>
            })}
            <SubmitButton fixed={true} value={isEdit ? "Opslaan" : "Voeg toe"}/>
          </form> 
        </div>
        <div className="column">
          <h4 className="input-label">Standaard planning</h4>
          <RadioButton setValue={HandleRepeatChoice} value={(repeat ? "ja" : "nee")} radioButtonName="Herhaal elke week" options={["ja", "nee"]}></RadioButton>
          <DateTimeSelect useTM={true} useDateTM={false} useDay={false} setDateTM={setDateTM} setTimeTM={setTimeTM} timeValueTM={timeTM} dateValueTM={dateTm} setDay={setDay} dayValue={day} timeValue={time} setTime={setTime} setDate={setStartDate} dateValue={startDate} selectName="dateTimeSelect" onClickButton={addDateTime}></DateTimeSelect>
          <div className='schedules'>
            {
              schedules.map(x => {
                return <div key={x.id}>
                  <TableRow repeat={x.repeat} onDeleteClick={() => deleteSchedule(x.id ?? 0)} subtitle={scheduleService.getTimeSubString(x.from) + "-" + scheduleService.getTimeSubString(x.to)} title={scheduleService.getDateSubString(x.date)}></TableRow>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoleEdit
