import QuestionDTO from "./QuestionDTO";
import DateTimeDTO from "./DateTimeDTO";

class QuestionnaireDTO {
    id: number = 0;
    name: string = "";
    questions: QuestionDTO[] = [];
    goal_id: number = 0;
    dateTime?: DateTimeDTO[] = [];
    alwaysShow: boolean = false;

    constructor(questionDTO?: QuestionnaireDTO) {
        Object.assign(this, questionDTO);
    }
}

export default QuestionnaireDTO;