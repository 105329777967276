import './LocationDetail.css';
import { useState, useEffect } from 'react';
import LocationService from '../../services/location/LocationService';
import LocationDTO from '../../dto/LocationDTO';
import { Link, useParams } from "react-router-dom";
import DetailTopSection from '../../components/detail-top-section/DetailTopSection';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Map from '../../components/map/Map';
import { Circle } from 'leaflet';
import createCircle from '../../adapters/circle/CircleFactory';
import TableRow from '../../components/tablerow/TableRow';
import FranchiseService from '../../services/franchise/FranchiseService';
import FranchiseDTO from '../../dto/FranchiseDTO';

function LocationDetail() {
  const [location, setLocation] = useState<LocationDTO>();
  const [circles] = useState([] as Circle[]);
  const [franchise, setFranchise] = useState<FranchiseDTO>();

  const params = useParams();

  useEffect(() => {
    const locationService = new LocationService();
    locationService.loadOne(id)
    .then(val => {
      setLocation(val);
      circles.push(createCircle(val.longitude, val.latitude, val.radius, "blue"))
      circles.push(createCircle(val.area.longitude, val.area.latitude, val.area.radius, "red"))
    })

  }, [])

  useEffect(() => {
    const franchiseService = new FranchiseService();
    if(location && location.franchise.id){
      franchiseService.loadOne(location.franchise.id)
      .then(val => {
        setFranchise(val);
      })
    }
  }, [location])

  if(params.id === undefined) {
    return <div></div>
  }

  const id: number = Number.parseInt(params.id);

  if(!location || !location.area || !location.linkedInterventions) {
    return null;
  }

  return (
    <div>
      <Breadcrumb lastItem={location.name}/>
      <DetailTopSection pageTitle={location.name} buttonTitle={'Wijzigen'} navigationLink={'/locaties/wijzigen/' + location.id} subheading={'Locaties'}/>
      <div className='flex-container'>
        <div>
          <table className="location-detail-table">
            <tbody>
              <tr>
                <td className="table-min-width">Gebied:</td>
                <td><Link to={"/gebieden/" + location.area.id}>{location.area.name}</Link></td>
              </tr>
              <tr>
                <td className="table-min-width">Franchise:</td>
                <td><Link to={"/franchises/" + location.franchise.id}>{location.franchise.name}</Link></td>
              </tr>
              <tr>
                <td className="table-min-width">Lengtegraad:</td>
                <td>{location.longitude + "°"}</td>
              </tr>
              <tr>
                <td className="table-min-width">Breedtegraad:</td>
                <td>{location.latitude + "°"}</td>
              </tr>
              <tr>
                <td className="table-min-width">Straal:</td>
                <td>{location.radius + " meter"}</td>
              </tr>
            </tbody>
          </table>
          <div className="interventions">
            <h4>Interventies</h4>
            {
            location.linkedInterventions.length !== 0  ? 
            location.linkedInterventions.map(intervention => {
              return <a href={"/interventies/" + intervention.type + "/" + intervention.id}><p id={intervention.id.toString()}>{intervention.name}</p></a>
            }) : 
            <p>Er zijn nog geen gekoppelde interventies!</p>
            }
          </div>
          <div className="interventions">
            <h4>Interventies van franchise</h4>
            {
            franchise && franchise?.linkedInterventions.length !== 0  ? 
            franchise?.linkedInterventions.map(intervention => {
              return <a href={"/interventies/" + intervention.type + "/" + intervention.id}><p id={intervention.id.toString()}>{intervention.name}</p></a>
            }) :  
            <p>Er zijn geen interventies van de franchise</p>
            }
          </div>
        </div>
        <div className="dateTimes">
            <h3>Locatie interventie tijden</h3>
            {location.dateTime &&
              location.dateTime.length > 0 ?  
              location.dateTime.map(dateTime => {
                return <TableRow wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                  subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
              })
              : <h5>Geen specifieke tijden</h5>
            }
        </div>
        <Map viewCoords={[location.latitude, location.longitude]} viewZoom={15} circles={circles}></Map>
      </div>
    </div>
  );
}

export default LocationDetail;
