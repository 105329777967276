import QuestionDTO from "../../../dto/QuestionDTO";
import QuestionnaireDTO from "../../../dto/QuestionnaireDTO";
import DateTimeDTO from "../../../dto/DateTimeDTO";

class QuestionnaireCreateRequestDTO {
    name: string = "";
	questions: QuestionDTO[] = [];
    goal_id: number = 0;
    dateTime: DateTimeDTO[] = [];
    alwaysShow: boolean = false;

    constructor(questionnaireDTO: QuestionnaireDTO) {
        this.name = questionnaireDTO.name;
        this.questions = questionnaireDTO.questions;
        this.goal_id = questionnaireDTO.goal_id;
        this.alwaysShow = questionnaireDTO.alwaysShow;
        if(questionnaireDTO.dateTime){
            this.dateTime = questionnaireDTO.dateTime;
        }
    };
}

export default QuestionnaireCreateRequestDTO;
