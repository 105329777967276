import FranchiseDTO from "../../dto/FranchiseDTO";
import InterventionDTO from "../../dto/InterventionDTO";
class FranchiseUpdateRequestDTO {
    id: number = 0;
    name: String = "";
    linkedInterventions : number[] = [];
    delay: number = 0;

    constructor(franchiseDTO: FranchiseDTO) {
        this.id = franchiseDTO.id;
        this.name = franchiseDTO.name;
        this.linkedInterventions = franchiseDTO.linkedInterventions.map(x => {return x.id});
        this.delay = franchiseDTO.delay;
    };
}

export default FranchiseUpdateRequestDTO;