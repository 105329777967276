import './UserEdit.css';
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Input from '../../components/input/Input';
import SubmitButton from '../../components/submit-button/SubmitButton';
import UserDTO from '../../dto/UserDTO';
import UserService from '../../services/user/UserService';
import Select from '../../components/select/Select';
import Option from '../../components/select/Option';
import RoleDTO from '../../dto/RoleDTO';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import RoleService from '../../services/role/RoleService';
import GoalDTO from '../../dto/GoalDTO';
import GoalService from '../../services/goal/GoalService';
import GenderDTO from '../../dto/GenderDTO';
import EducationDTO from '../../dto/EducationDTO';
import RadioButton from '../../components/radioButton/RadioButton';

const UserEdit : React.FC = () => {
  const [user, setUser] = useState({} as UserDTO);
  const [service, setService] = useState({} as UserService);
  const [errors, setErrors] = useState({} as any);
  const [allRoles, setAllRoles] = useState([] as RoleDTO[]);
  const [allGenders, setAllGenders] = useState([] as GenderDTO[]);
  const [allEducations, setAllEducations] = useState([] as EducationDTO[]);
  const [goals, setGoals] = useState([] as GoalDTO[]);
  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if(!isEdit) {
      await service.create(user)
        .then(() => {
          toast.success("Gebruiker aangemaakt!");
          navigate("/gebruikers");
        }).catch(err => {
          console.log(err.response.data);
          setErrors(err.response.data);
          return;
        });
    } else {
      await service.update(user)
        .then(response => {
          toast.success("Gebruiker bijgewerkt!");
          navigate("/gebruikers");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    }
  }

  useEffect(() => {
    const roleService = new RoleService();
    const userService = new UserService();
    setAllGenders([{name: "Man", id: 0},{name: "Vrouw", id: 1},{name: "Anders", id: 2}, {name: "Wil ik niet zeggen", id: 3}]);
    setAllEducations([{name: "Geen/Basisonderwijs", id: 0},{name: "Vmbo/MBO 1/Onderbouw Havo of VWO", id: 1},{name: "MBO 2,3 of 4/Havo/VWO", id: 2}, {name: "HBO/WO", id: 3}]);
    setService(userService);
    roleService
    .loadAll()
    .then(roles => {
      setAllRoles(roles);
    })
    if(!isEdit) {
      setUser(new UserDTO())
    } else {
      userService.loadOne(id)
      .then(val => {
        setUser(val);
      })
    }
  }, [])

  useEffect(() => {
    const goalService = new GoalService();

    goalService
      .loadAll()
      .then(goals => {
        setGoals(goals);
      })
  }, [])

  const id: number = Number.parseInt(params.id === undefined ? "0" : params!.id);
  const isEdit: boolean = id !== 0;

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUser({...user, [e.target.id]: e.target.value})
  }

  const genderIdFromString = (gender:string) => {
    allGenders.forEach(element => {
      if(element.name == gender){
        return element.id;
      }
    })
    return 0;
  } 

  const changeSelectedGoal = (id:string) => {
    setUser({...user, "goalId": Number(id)});
  }

  if(!goals || !user || !user.linkedGoal) {
    return null;
  }

  const updateRole = (id:string) => {
   setUser({...user, "roleId": Number(id)});
  }

  const updateGender = (gender:string) => {
    setUser({...user, "gender": gender});
   }

   const updateEducation = (education:string) => {
    setUser({...user, "education": education});
   }

  if(!user || !user.role) {
    return <div></div>
  }

  const handleTypeChoice = (option: string) => {
    user.showInterventions = (option == "ja" ? true : false);
  }

  return (
    <div>
      <Breadcrumb lastItem={user.username}/>
      <h2>{isEdit ? user.username + " Wijzigen" : "Gebruiker aanmaken"}</h2>
      <form className='user-edit-form' onSubmit={onSubmit}>
        <RadioButton setValue={handleTypeChoice} value={(user.showInterventions ? "ja" : "nee")} radioButtonName="Laat interventies zien" options={["ja", "nee"]}></RadioButton>
        <p style={{color: "red"}}>{typeof errors  === 'string' ? errors : ""}</p>
        <Input placeholderText={'Naam'} inputName={'username'} inputType={'text'} inputLabel={'Naam'} onChange={handleChange} value={user.username} errors={errors.username}/>
        <br/>
        <Input placeholderText={'Wachtwoord'} inputName={'password'} inputType={'text'} inputLabel={'Password'} onChange={handleChange} value={user.password} errors={errors.password}/>
        <br/>
        <Input placeholderText={'Voornaam'} inputName={'name'} inputType={'text'} inputLabel={'Voornaam'} onChange={handleChange} value={user.name} errors={errors.name}/>
        <br/>
        <Input placeholderText={'Achternaam'} inputName={'surname'} inputType={'text'} inputLabel={'Achternaam'} onChange={handleChange} value={user.surname} errors={errors.surname}/>
        <br/>
        <Input placeholderText={'Postcode'} inputName={'zipCode'} inputType={'text'} inputLabel={'Postcode'} onChange={handleChange} value={user.zipCode.replace(" ", "")} errors={errors.zipCode}/>
        <br/>
        <Input placeholderText={'Huisnummer'} inputName={'houseNumber'} inputType={'number'} inputLabel={'Huisnummer'} onChange={handleChange} value={user.houseNumber} errors={errors.houseNumber}/>
        <br/>
        <Input placeholderText={'Straatnaam'} inputName={'streetName'} inputType={'text'} inputLabel={'Straatnaam'} onChange={handleChange} value={user.streetName} errors={errors.streetName}/>
        <br/>
        <Input placeholderText={'Geboortedatum'} inputName={'birthDate'} inputType={'date'} inputLabel={'Geboorte datum'} onChange={handleChange} value={user.birthDate} errors={errors.birthDate}/>
        <br/>
        <Select saveAsName={true} placeholderText={'Kies een opleiding'} value={user.education.toString()} selectName={'opleiding'} selectLabel={'Opleiding'} onChange={updateEducation} errors={errors.education} options={allEducations.map(x => {
          let option = new Option();
          option.id = x.id.toString();
          option.name = x.name;
          return option;
        })} />        <br/>
        <Select saveAsName={true} placeholderText={'Kies een geslacht'} value={user.gender.toString()} selectName={'gender'} selectLabel={'Geslacht'} onChange={updateGender} errors={errors.gender} options={allGenders.map(x => {

          let option = new Option();
          option.id = x.id.toString();
          option.name = x.name;
          return option;
        })} />
        <br/>
        <Input placeholderText={'Email'} inputName={'email'} inputType={'text'} inputLabel={'Email'} onChange={handleChange} value={user.email} errors={errors.email}/>
        <br/>
        <Select placeholderText={'Kies een rol'} value={user.roleId.toString()} selectName={'id'} selectLabel={'Rol'} onChange={updateRole} errors={errors.roleId} options={allRoles.map(x => {
          let option = new Option();
          option.id = x.id.toString();
          option.name = x.name;
          return option;
        })} />
        <br/>
        <Select 
          placeholderText={'Kies een Doelstelling'} 
          selectName={'new-goal'} 
          onChange={changeSelectedGoal} 
          selectLabel={'Doelstelling'} 
          value={user.goalId.toString()}
          errors={errors.goalId}
          options={goals.map(goal => {
            let option = new Option();
            option.id = goal.id.toString();
            option.name = goal.name;

            return option;
          })
          }
        />
        <br/>
        <SubmitButton fixed={true} value={isEdit ? "Opslaan" : "Voeg toe"}/>
      </form>
    </div>
  );
}

export default UserEdit
