import UserDTO from "../../dto/UserDTO";
import RoleDTO from "../../dto/RoleDTO";

class UserCreateRequestDTO {
    username: string = "";
    email: string = "";
	password: string= "";
    role: RoleDTO = new RoleDTO();
    roleId: number = 0;
    goalId: number = 0;
    name: string = "";
    surname: string = "";
    zipCode: string = "";
    birthDate: string = "";
    education: string = "";
    gender: string = "";
    houseNumber: number = 0;
    streetName: string = "";
    showInterventions: boolean = true;

    constructor(userDTO: UserDTO) {
        this.username = userDTO.username;
        this.email = userDTO.email;
		this.password = userDTO.password;
        this.role = userDTO.role;
        this.roleId = userDTO.roleId;
        this.goalId = userDTO.goalId;
        this.name = userDTO.name;
        this.surname = userDTO.surname;
        this.zipCode = userDTO.zipCode;
        this.birthDate = userDTO.birthDate;
        this.education = userDTO.education;
        this.gender = userDTO.gender;
        this.houseNumber = userDTO.houseNumber;
        this.streetName = userDTO.streetName;
        this.showInterventions  = userDTO.showInterventions;
    };
}

export default UserCreateRequestDTO;
