import InterventionDTO from "./InterventionDTO";

class ScheduleDTO {   
    id?: number;
    date: string = "";
    from: string = "";
    to: string = "";
    repeat: boolean = false;
    
    constructor(ScheduleDTO?: ScheduleDTO) {
        Object.assign(this, ScheduleDTO);
    }
}

export default ScheduleDTO;
