import './CommandEdit.css';
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Input from '../../components/input/Input';
import Select from '../../components/select/Select';
import SubmitButton from '../../components/submit-button/SubmitButton';
import CommandDTO from '../../dto/CommandDTO';
import DateTimeDTO from '../../dto/DateTimeDTO';
import CommandService from '../../services/intervention/command/CommandService';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import GoalDTO from '../../dto/GoalDTO';
import Option from '../../components/select/Option';
import GoalService from '../../services/goal/GoalService';
import DateTimeSelect from '../../components/dateTimeSelect/DateTimeSelect';
import TableRow from '../../components/tablerow/TableRow';
import RadioButton from '../../components/radioButton/RadioButton';


const CommandEdit : React.FC = () => {
  const [command, setCommand] = useState({} as CommandDTO);
  const [service, setService] = useState({} as CommandService);
  const [errors, setErrors] = useState({} as any);
  const [allGoals, setAllGoals] = useState([] as GoalDTO[]);
  const [goal, setGoal] = useState(0 as number);
  const [currentGoal, setCurrentGoal] = useState({} as GoalDTO);
  const [startDate, setStartDate] = useState(null as unknown);
  const [time, setTime] = useState('' as string);
  const [day, setDay] = useState(String);
  const [timeTM, setTimeTM] = useState('' as string);
  const [dateTm, setDateTM] = useState(null as unknown);
  const params = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if(!isEdit) {
      const service = new CommandService();
      setService(service)
      setCommand({...command, goal_id: Number(id)});
      await service.create(command)
        .then(() => {
          toast.success("Commando aangemaakt!");
          navigate("/interventies");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    } else {
      await service
        .update(command)
        .then(response => {
          toast.success("Commando bijgewerkt!");
          navigate("/interventies");
        }).catch(err => {
          setErrors(err.response.data);
          return;
        });
    }
  }

  const getCurrentGoal = () => {
    if(command.goal_id){
      const goalService = new GoalService();
      goalService.loadOne(command.goal_id)
      .then(val => {
        setCurrentGoal(val);
      })
    }
  };

  useEffect(() => {
    const commandService = new CommandService();
    const goalService = new GoalService();
    setService(commandService)
    goalService.loadAll()
    .then(val => {
      setAllGoals(val);
    });
    if(!isEdit) {
      setCommand(new CommandDTO())
    } else {
      commandService.loadOne(id)
      .then(val => {
        setCommand(val);
      })
    }
  }, [])

  useEffect(() => {
    getCurrentGoal()
  }, [command.goal_id])

  const id: number = Number.parseInt(params.id === undefined ? "0" : params!.id);
  const isEdit: boolean = id !== 0;

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCommand({...command, [e.target.id]: e.target.value})
  }
 
  const addDateTime = (e: FormEvent) => {
    e.preventDefault();

    var dateArray : DateTimeDTO[] = [];
    if(command.dateTime){
      dateArray = command.dateTime;
    }
    
      const newDateTime : DateTimeDTO = new DateTimeDTO();
      if(day){newDateTime.day = day;}
      if(startDate && startDate instanceof Date){
        newDateTime.date = startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear();
      }
      if(time){newDateTime.time = time;}else{newDateTime.time = ""}
      if(dateTm && dateTm instanceof Date){newDateTime.dateTm = dateTm.getDate() + "-" + (dateTm.getMonth() + 1) + "-" + dateTm.getFullYear();}
      if(timeTM){newDateTime.timeTm = timeTM;}
      newDateTime.id = command.dateTime ? command.dateTime.length + 1 : 0;
      dateArray.push(newDateTime);
    
    const newCommand : CommandDTO = new CommandDTO();
    newCommand.commandText = command.commandText;
    newCommand.goal_id = command.goal_id;
    newCommand.id = command.id;
    newCommand.name = command.name;
    newCommand.dateTime = dateArray;
    newCommand.alwaysShow = command.alwaysShow;
    
    setCommand(newCommand);
  }
  
  const updateGoal = (id:number) => {
    setGoal(id);
    setCommand({...command, goal_id: Number(id)});
  }

  const removeDateTime = (id:number) => {
    const newCom : CommandDTO = new CommandDTO(command);
    if(newCom.dateTime){
      newCom.dateTime = newCom.dateTime.filter(x => x.id !== id);
    }
    setCommand(newCom);
  }

  const handleAlwaysShow = (option: string) => {
    setCommand({...command, alwaysShow: (option == "ja" ? true : false)});
  }


  return (
    <div> 
      <div className="fields-row">
          <div className="column">
          <Breadcrumb lastItem={command.name} itemsToRemove={["commando"]}/>
          <h2>{isEdit ? command.name + " Wijzigen" : "Commando aanmaken"}</h2>
            <form onSubmit={onSubmit}>
                <Input placeholderText={'Naam'} inputName={'name'} inputType={'text'} inputLabel={'Naam'} onChange={handleChange} value={command.name} errors={errors.name}/>
                <br/>
                <Input placeholderText={'Tekst'} inputName={'commandText'} inputType={'text'} inputLabel={'Tekst'} onChange={handleChange} value={command.commandText} errors={errors.commandText}/>
                <br/>
                <Select dots={false} placeholderText={currentGoal.name} value={goal.toString()} selectName={'Goalid'} selectLabel={'Doelstelling'} onChange={updateGoal} options={allGoals.map(x => {
                    let option = new Option();
                    option.id = x.id.toString(); 
                    option.name = x.name.toString(); 
                    return option;
                  })}
                  />
                <br/>
                {
                  command.alwaysShow != undefined &&
                  <RadioButton setValue={handleAlwaysShow} value={(command.alwaysShow ? "ja" : "nee")} radioButtonName="Interventie zonder locatie" options={["ja", "nee"]}></RadioButton>
                }
                <br/>
                <SubmitButton fixed={true} value={isEdit ? "Opslaan" : "Voeg toe"}/>
          </form>
        </div>
        <div className="column dateTimeSelect">
          <DateTimeSelect useTM={true} setDateTM={setDateTM} setTimeTM={setTimeTM} timeValueTM={timeTM} dateValueTM={dateTm} setDay={setDay} dayValue={day} timeValue={time} setTime={setTime} setDate={setStartDate} dateValue={startDate} selectName="dateTimeSelect" onClickButton={addDateTime}></DateTimeSelect>
          <br/>
          {command.dateTime &&
              command.dateTime.map(dateTime => {
                  return <TableRow onDeleteClick={() => removeDateTime(dateTime.id)} wrap={true} title={(dateTime.day ? dateTime.day : "") + (!dateTime.day && dateTime.date ? dateTime.date : "")}   
                    subtitle={(dateTime.date && !dateTime.day ? (dateTime.dateTm ? " Tot: " + dateTime.dateTm : "") : "") + (dateTime.time && dateTime.timeTm ? " Van: " + dateTime.time + " Tot: " + dateTime.timeTm : dateTime.time ? "Op: " + dateTime.time : "") + (dateTime.day && dateTime.date && dateTime.dateTm ? " Van: " + dateTime.date + " Tot: " + dateTime.dateTm : "") } />
              })
          }
        </div>
      </div>
    </div>
  );
}

export default CommandEdit
