import networkAdapter from "../../adapters/network/NetworkAdapterFactory";
import LoginDTO from "../../dto/LoginDTO";
import LoginRequestDTO from "./LoginRequestDTO";
import jwt_decode from "jwt-decode";

class LoginService {
    async login(value: LoginDTO): Promise<any> {
        return networkAdapter.post("auth", new LoginRequestDTO(value));
    }

    getLocalStorage(key: string) : any{
        const value = localStorage.getItem(key)
        return value
    }

}

export default LoginService;