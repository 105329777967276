import AnswerDTO from "../../../dto/AnswerDTO";
import QuestionDTO from "../../../dto/QuestionDTO";
import DateTimeDTO from "../../../dto/DateTimeDTO";

class CommandUpdateRequestDTO {
    id: number = 0;
    name: string = "";
	question: string = "";
    answers: AnswerDTO[] = [];
    goal_id: number = 0;
    dateTime: DateTimeDTO[] = [];
    questionType: string = "";
    alwaysShow: boolean = false;


    constructor(questionDTO: QuestionDTO) {
        this.id = questionDTO.id;
        this.name = questionDTO.name;
        this.question = questionDTO.question;
        this.answers = questionDTO.answers;
        this.goal_id = questionDTO.goal_id;
        this.alwaysShow = questionDTO.alwaysShow;
        this.questionType = questionDTO.questionType;

        if(questionDTO.dateTime){
            this.dateTime = questionDTO.dateTime;
        }
    };
}

export default CommandUpdateRequestDTO;
