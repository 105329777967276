import FranchiseDTO from "../../dto/FranchiseDTO";

class FranchiseCreateRequestDTO {
    name: String = "";
    linkedInterventions: number[] = [];
    delay: number = 0;

    constructor(franchiseDTO: FranchiseDTO) {
        this.name = franchiseDTO.name;
        this.linkedInterventions = franchiseDTO.linkedInterventions.map(x => {return x.id});
        this.delay = franchiseDTO.delay;
    };
}

export default FranchiseCreateRequestDTO;