import './GoalList.css';
import { useState, useEffect } from 'react';
import TopSection from '../../components/list-top-section/ListTopSection';
import TableRow from '../../components/tablerow/TableRow';
import GoalDTO from '../../dto/GoalDTO';
import GoalService from '../../services/goal/GoalService';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function GoalList() {
  const [goals, setGoals] = useState([] as GoalDTO[]);
  const [service, setService] = useState({} as GoalService);
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const goalService = new GoalService();
    setService(goalService)
    goalService.loadAll()
    .then(val => {
      setGoals(val);
    })
  }, [])

  const deleteGoal = (id: number) => {
    confirmAlert({
      title: 'Verwijderen',
      message: 'Weet je zeker dat je deze doelstelling wilt verwijderen?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => {    
            service.delete(id).then(() => {
              setGoals(goals.filter(x => x.id !== id))
            }).catch(() => {
              toast.error("Er zitten gebruikers gekoppeld aan deze doelstelling en kan daarom niet verwijderd worden!");
            });
          }
        },
        {
          label: 'Nee',
          onClick: () => {}
        }
      ]
    });
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()

    setSearchTerm(e.target[0].value)
  }

  const handleSearchChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div>
      <Breadcrumb/>
      <TopSection pageTitle={'Doelstellingen'} buttonTitle={'Toevoegen'} navigationLink={'/doelstellingen/wijzigen/0'} onSearchChange={handleSearchChange} onSubmit={onSubmit}/>
        {goals.filter((goal) => {
          if(searchTerm === "") return goal
          if(goal.name.toLowerCase().includes(searchTerm.toLowerCase()) || goal.id.toString() == searchTerm.toLowerCase()) return goal
        }).map(goal => {
          return (
            <div key={goal.id}>
              <TableRow title={goal.name + " - " + goal.id} onEditLink={"/doelstellingen/wijzigen/" + goal.id} onDeleteClick={() => goal.name == "ADMIN" ? console.log("mag niet") : deleteGoal(goal.id)} navigationLink={"/doelstellingen/" + goal.id}/> {/* TODO: ADMIN doelstelling is gekoppeld aan beheerders, kijken wat me daarmee moeten */}
            </div>
          )
        })}
    </div>
  );
}

export default GoalList;
