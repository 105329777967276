import GoalDTO from "./GoalDTO";
import ProfileAnswerDTO from "./ProfileAnswerDTO";
import RoleDTO from "./RoleDTO";

class UserDTO {
    id: number = 0;
    username: string = "";
    email: string = "";
    password: string= "";
    role: RoleDTO = new RoleDTO();
    roleId: number = 0;
    goalId: number = 0;
    linkedGoal: GoalDTO = new GoalDTO();
    answers: ProfileAnswerDTO[] = [];
    name: string = "";
    surname: string = "";
    zipCode: string = "";
    birthDate: string = "";
    education: string = "";
    gender: string = "";
    houseNumber: number = 0;
    streetName: string = "";
    showInterventions: boolean = true;
    constructor(userDto?: UserDTO) {
        Object.assign(this, userDto);
    }
}

export default UserDTO;
