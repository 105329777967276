
import './DateTimeSelect.css';
import { useNavigate } from "react-router-dom";
import RadioButton from '../radioButton/RadioButton';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import ScheduleService from '../../services/schedule/ScheduleService';

interface Props {
    selectName: String,
    onClickButton: any,
    setDate: any,
    dateValue: any,
    setTime: Function,
    timeValue: string,
    dayValue: string,
    setDay: Function
    setDateTM?: any,
    dateValueTM?: any,
    setTimeTM?: Function,
    timeValueTM?: string,
    useTM?: boolean,
    useDateTM?: boolean,
    useDay?: boolean
}

function DateTimeSelect({selectName, onClickButton, setDate, dateValue, setTime, timeValue, dayValue, setDay, setDateTM, dateValueTM, setTimeTM, timeValueTM, useTM = false, useDateTM = true, useDay = true}: Props ) {

    const navigate = useNavigate();
    const scheduleService = new ScheduleService();

    return (
        <div>   
            {   useDay ?
                <span>
                    {
                    !setDateTM ?
                        <RadioButton disabled={dateValue ? true : false} setValue={setDay} value={dayValue} radioButtonName="Dag" options={["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag", "Geen"]}/>
                    :
                        <RadioButton disabled={dateValueTM || dateValue ? true : false} setValue={setDay} value={dayValue} radioButtonName="Dag" options={["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag", "Geen"]}/>
                    }
                </span>
                : <></>
            }
            <br/>
            <div className="inputGroup">
                <h4 className="input-label input-label- labelPadding">Datum</h4>
                {
                    setDateTM ?
                    <span>
                        <div className="fields-row">
                            <div className='Column'>
                                <DatePicker dateFormat={"dd-MM-yyyy"} isClearable placeholderText='Date' selected={dateValue} onChange={(date:Date) => setDate(date)} />
                            </div>
                            <h5 className='day'>{dateValue != null ? scheduleService.dateToDayString(dateValue) : ""}</h5>
                        </div>
                        {
                        useDateTM ?
                        <span>
                            <p>t/m</p>
                            <DatePicker dateFormat={"dd-MM-yyyy"} isClearable placeholderText='Date t/m' selected={dateValueTM} onChange={setDateTM ? (date:Date) => setDateTM(date) : () => {}} />
                        </span>
                        : <></>
                        }
                    </span> 
                    :
                    <span>
                        <DatePicker dateFormat={"dd-MM-yyyy"} isClearable placeholderText='Date' selected={dateValue} onChange={(date:Date) => setDate(date)} />
                    </span>
                }
            </div>
            <br/>
            <div className="inputGroup">
                <h4 className="input-label input-label-">Tijd</h4>
                {
                    useTM ?
                    <span>
                        <TimePicker onChange={(time:any) => setTime(time)} value={timeValue} disableClock={true} />
                        <p>t/m</p>
                        <TimePicker onChange={setTimeTM ? (time:any) => setTimeTM(time) : () => {}} value={(timeValueTM ? timeValueTM : '')} disableClock={true} />
                    </span>
                    :
                    <span>
                        <TimePicker onChange={(time:any) => setTime(time)} value={timeValue} disableClock={true} />
                    </span>
                }
            </div>
            <br/>
            <button className="submit-button-enabled" onClick={onClickButton}>Voeg toe</button>
        </div>
    );
}

export default DateTimeSelect;